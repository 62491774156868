<template>
  <el-container>
    <el-header>
      <img src="../assets/logo.png" />
      <p class="title">科大智能信息管理平台</p>
      <div class="header-avatar">
        <el-avatar size="medium" :src="userInfo.avatar"></el-avatar>
        <el-dropdown>
          <span class="el-dropdown-link">
            {{ userInfo.username }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <router-link :to="{name:'UserCenter'}">修改密码</router-link>
            </el-dropdown-item>
            <el-dropdown-item @click.native="logout">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-header>
    <el-container class="my-cont">
      <el-aside width="200px">
        <SideMenu></SideMenu>
      </el-aside>
      <el-main>
        <Tabs></Tabs>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
  import SideMenu from "./inc/SideMenu"
  import Tabs from "./inc/Tabs"

  export default {
    name: "Home",
    components: {
      SideMenu,
      Tabs
    },
    data() {
      return {
        userInfo: {
          id: "",
          username: "",
          avatar: ""
        }
      }
    },
    created() {
      this.getUserInfo()
    },
    mounted() {

    },
    methods: {
      // _click(){
      //
      // },
      getUserInfo() {
        this.$axios.get("/sys/userInfo").then(res => {
          this.userInfo = res.data.data
        })
      },
      logout() {
        this.$emit("click")
        this.$axios.post("/logout").then(res => {
          localStorage.clear()
          sessionStorage.clear()

          this.$store.commit("resetState")
          this.$router.push("/login")
        })
      }
    }
  }
</script>

<style scoped>
  .el-header {
    display: flex;
    align-items: center;
  }
  .el-header>img {
    width: 80px;
  }
  .el-header>p {
    flex: 1;
    margin-left: 10px;
  }
  .header-avatar{
    display: flex;
    align-items: center;
  }
  .el-dropdown {
    color: white;
  }
  .el-avatar {
    margin-right: 5px;
  }
  .el-dropdown-link {
    cursor: pointer;
  }

  .el-container {
    padding: 0;
    margin: 0;
    height: 100%;
    background-color: #f0f0f0;
  }
  .my-cont{
    height: calc(100% - 70px);
  }
  .el-header {
    background-color: #13638e;
    color: #333;
    text-align: center;
    height: 70px !important;
    line-height: 70px;
  }

  .el-aside {
    background-color: #D3DCE6;
    color: #333;
    line-height: 200px;
  }

  .el-main {
    color: #333;
    padding: 0;
  }

  a {
    text-decoration: none;
  }
  
  p.title {
    font-size: 24px;
    color: white;
    text-align: left;
    line-height: 70px;
    font-weight: bold;
  }
  .el-main {
    height: calc(100% - 20px);
    box-sizing: border-box;
    margin: 10px;
    background-color: white;
  }
  .el-main > div:nth-of-type(1) {
    margin-left: -1px;
  }
  .el-main > div:nth-of-type(2) {
    padding: 20px 20px;
    height: calc(100% - 81px);
    overflow-y: auto;
  }
  .el-main >>> .el-tabs__header {
    margin: 0;
  }
</style>
