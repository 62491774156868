import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui'
import Mint from 'mint-ui'
import "element-ui/lib/theme-chalk/index.css"
import '../index.css'
import axios from './axios'
import global from './globalFun'

import { $replaceString } from './util/$replaceString.js'
Vue.prototype.$replaceString = $replaceString

Vue.config.productionTip = false
Vue.prototype.$axios = axios

Element.Dialog.props.closeOnClickModal.default = false
Element.Dialog.props.closeOnPressEscape.default = false

Vue.use(Element)
Vue.use(Mint)
// require("./mock.js")

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

