import moment from 'moment'

export function $replaceString (arr, items) {
  let objKeys = Object.keys(arr[0])
  let newArr = []
  arr.map(item => {
    let newObj = {}
    for(let key in item) {
      if(!isNaN(item[key]) && typeof(item[key]) !== 'string') {
        if(key.indexOf('比例') !== -1 || key.indexOf('率') !== -1) {
          newObj[key] = Math.round(item[key]*100)+'%'
        } else if(key.indexOf('日期') !== -1 || key.indexOf('时间') !== -1) {
          newObj[key] = moment((item[key].getTime()+86400000)).format('YYYY-MM-DD')
        } else {
          newObj[key] = Math.round(item[key])
        }
      } else {
        key.indexOf('区域') !== -1 ? newObj[key] = item[key].substring(0, item[key].indexOf('（') !== -1 ? item[key].indexOf('（') : 99) : newObj[key] = item[key]
      }
    }
    let newArrS = JSON.stringify(newObj)
    items.map((item, index) => {
      newArrS = newArrS.replace(new RegExp(objKeys[index]), item)
    })
    newArr.push(JSON.parse(newArrS))
  })
  return newArr
}
