import Vue from 'vue'
import Vuex from 'vuex'
import menus from "./modules/menus";

Vue.use(Vuex)

export default new Vuex.Store({
  state: sessionStorage.getItem('state') ? JSON.parse(sessionStorage.getItem('state')) : {
    token: '',
    data01: [],
    data03: []
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
      localStorage.setItem("token", token)
    },
    SET_DATA01: (state, data01) => {
      state.data01 = data01
    },
    SET_DATA03: (state, data03) => {
      state.data03 = data03
    }
  },
  actions: {},
  modules: {
    menus
  }
})
