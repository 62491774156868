<template>
  <div class="main">
    <img src="../assets/mian-bg.png" />
    <p>建设中...</p>
  </div>
</template>

<script>
export default {
  name: "Index"
}
</script>

<style scoped>
  .main {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .main>img {
    width: 400px;
    height: 376px;
    opacity: .6;
  }
  .main>p {
    font-size: 18px;
    font-weight: bold;
    color: #646464;
    margin-top: -40px;
    margin-bottom: 140px;
     opacity: .6;
  }
</style>