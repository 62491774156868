<template>
  <div id="app" v-if="isReload">
    <router-view  />
  </div>
</template>

<script>
  import setNav from './util/setNav.js'
  import router from './router'

  export default {
    name: "App",
    provide() {
      return {
        reload: this.reload,
      }
    },
    data() {
      return {
        isReload: true
      }
    },
    watch: {
      $route(to, from) {
        if (to.path != '/login') {
          let obj = {
            name: to.name,
            title: to.meta.title
          }
          this.$store.commit("addTab", obj)
        }
      }
    },
    mounted() {
      if (router.currentRoute.path !== "/") {
        setNav()
      }
      window.addEventListener('unload', this.saveState)
    },
    methods: {
      saveState() {
        sessionStorage.setItem('menusState', JSON.stringify(this.$store.state.menus))
        sessionStorage.setItem('state', JSON.stringify(this.$store.state))
      },
      reload() {
        this.isReload = false
        this.$nextTick(() => {
          this.isReload = true
        })
      }
    }
  }
</script>

<style>
  html,
  body,
  #app {
    font-family: 'Helvetica Neue', 'Hiragino Sans GB', 'WenQuanYi Micro Hei', 'Microsoft Yahei', sans-serif;
    height: 100%;
    padding: 0;
    margin: 0;
    font-size: 15px;
  }
</style>
