import axios from 'axios'
import Element from 'element-ui'
import router from './router'
import store from './store'

axios.defaults.baseURL = "http://trace.csgpower.com.cn:9001/BMS"    //阿里云服务器地址
// axios.defaults.baseURL = "http://10.10.8.7:8089/BMS"
// axios.defaults.baseURL = "http://10.10.8.224:8089/BMS"
// axios.defaults.baseURL = "http://10.10.1.163:8089/BMS"

const request = axios.create({
  // timeout: 5000,
  headers: {
    'Content-Type': "application/json; charset=utf-8"
  }
})


request.interceptors.request.use(config => {
  config.headers['Authorization'] = localStorage.getItem("token")
  return config
})

request.interceptors.response.use(
  response => {
    return response
    // let res = response.data
    
    // if (res.code === 200) {
    //   return response
    // } else {
    //   Element.Message.error(!res.msg ? '系统异常' : res.msg)
    //   return Promise.reject(response.data.msg)
    // }
  },
  error => {

    if (!error.response) {

    } else {
      if (error.response.data) {
        // error.massage = error.response.data.msg
      } 
      if (error.response.status === 401) {
        error.massage = '请重新登录'
        localStorage.setItem("token", null);
        router.push("/login")
      } 
    }
    
    Element.Message.error(error.massage?error.massage:'网络错误，请稍候重试。', {
      duration: 3000
    })
 
    return Promise.reject(error)
  }
)

export default request
