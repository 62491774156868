import store from "../store"
import router from "../router"
import axios from "../axios.js"

export default function() {
  let hasRoute = store.state.menus.hasRoutes
  let token = localStorage.getItem("token")
  axios.get("/sys/menu/nav", {
    headers: {
      Authorization: localStorage.getItem("token")
    }
  }).then(res => {
    // 拿到menuList
    store.commit("setMenuList", res.data.data.nav)
    // 拿到用户权限
    store.commit("setPermList", res.data.data.authoritys)
    // 动态绑定路由
    let newRoutes = router.options.routes
    res.data.data.nav.forEach(menu => {
      if (menu.children) {
        menu.children.forEach(e => {
          // 转成路由
          let route = menuToRoute(e)
          // 吧路由添加到路由管理中
          if (route) {
            router.addRoute('Home', route)
          }
        })
      }
    })
    hasRoute = true
    store.commit("changeRouteStatus", hasRoute)
  })
}
// 导航转成路由
 function menuToRoute(menu) {

  if (!menu.component) {
    return null
  }

  let route = {
    name: menu.name,
    path: menu.path,
    meta: {
      icon: menu.icon,
      title: menu.title
    }
  }
  route.component = () => import('@/views/' + menu.component + '.vue')

  return route
}
