import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Index from '../views/Index.vue'
import User from '../views/sys/User.vue'
import Role from '../views/sys/Role.vue'
import Menu from '../views/sys/Menu.vue'

import axios from "../axios"
import store from "../store"
import el from "element-ui/src/locale/lang/el";

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
    children: [{
        path: '/index',
        name: 'Index',
        meta: {
          title: "首页"
        },
        component: Index
      },
      {
        path: '/userCenter',
        name: 'UserCenter',
        meta: {
          title: "个人中心"
        },
        component: () => import('@/views/UserCenter.vue')
      },
      {
        path: '',
        redirect: 'index'
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/suggComplain',
    name: 'Complaints',
    component: () => import('@/views/Complaints.vue')
  },
  {
    path: '/reportMarket',
    name: 'ReportMarket',
    component: () => import('@/views/ReportMarket.vue')
  },
  {
    path: '/reportDetail/:id',
    name: 'ReportDetail',
    component: () => import('@/views/ReportDetail.vue')
  },
  {
    path: '/other',
    name: 'Other',
    component: () => import('@/views/Other.vue'),
    children: [{
        path: 'bg',
        name: 'BG',
        component: () => import('@/views/other/BG.vue')
      },
      {
        path: 'remind',
        name: 'Remind',
        component: () => import('@/views/other/Remind.vue')
      },
      {
        path: 'code',
        name: 'Code',
        component: () => import('@/views/other/Code.vue')
      },,
      {
        path: 'appointment',
        name: 'Appointment',
        component: () => import('@/views/other/Appointment.vue')
      },
      {
        path: '',
        redirect: 'bg'
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: 'bms',
  routes
});


router.beforeEach((to, from, next) => {
  let hasRoute = store.state.menus.hasRoutes
  let token = localStorage.getItem("token")
  if (to.path == '/suggComplain' || to.path == '/reportMarket' || to.path.indexOf('/reportDetail') !== -1 || to.path
    .indexOf('/other') !== -1) {
    next()
  } else {
    if (to.path == '/login') {
      next()
    } else if (!token) {
      next({
        path: '/login'
      })
    } else if (token && !hasRoute) {
      next()
    }
    next()
  }

})


export default router
